.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*slider*/
.slider-container {
  margin-top: 15px;
  width: calc(100%); /* Adjust based on your container's padding/margin */
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 8px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #4169e4; /* Blue background */
  border-radius: 50%; /* Make it a circle */
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #4169e4; /* Blue background */
  border-radius: 50%; /* Make it a circle */
  cursor: pointer;
}

/*selector*/
.link-block.w-inline-block {
  cursor: pointer;
  transition: background-color 0.1s ease; /* Add filter transition */
  background-color: black; /* Default color */
  user-select: none; /* Make the whole block non-selectable */
}

/* Modify hover styles to only apply when the platform is not selected */
@media (hover: hover) and (pointer: fine) {
  .platform-tiktok:not(.selected-tiktok):hover {
    background-color: #78c4cc; /* TikTok's hover color */
  }

  .platform-youtube:not(.selected-youtube):hover {
    background-color: #e04c3c; /* YouTube's hover color */
  }
}

/* TikTok selected state */
.selected-tiktok {
  box-shadow: inset 0 0 0 3px #78c4cc; /* Cyan inner border for TikTok */
  background-color: #78c4cc; /* Maintain the selected background color */
}

/* YouTube selected state */
.selected-youtube {
  box-shadow: inset 0 0 0 3px #e04c3c; /* Red inner border for YouTube */
  background-color: #e04c3c; /* Maintain the selected background color */
}

/* Make text non-selectable */
.heading-5 {
  user-select: none;
}

/* Make images non-draggable */
.image {
  user-select: none; /* Make the image non-selectable */
  -webkit-user-drag: none; /* Disable dragging in WebKit browsers */
  -moz-user-drag: none; /* Disable dragging in Mozilla browsers */
  -o-user-drag: none; /* Disable dragging in Opera browsers */
  user-drag: none; /* Disable dragging for other browsers */
  touch-action: none;
}

/* Apply touch feedback for mobile devices */
.platform-tiktok:not(.selected-tiktok):active {
  background-color: #78c4cc; /* TikTok's hover color */
}

.platform-youtube:not(.selected-youtube):active {
  background-color: #e04c3c; /* YouTube's hover color */
}


/* App.css */

.dropdown-menu-container {
  width: 100%; /* Makes the container take the full width of its parent */
}

.form-select {
  margin-top: 15px;
  width: 100%;
  font-size: 15px;
  padding-left: 15px;
  padding-right: 30px; /* Increase right padding to move the arrow icon inward */
  -webkit-appearance: none; /* Removes default chrome and safari styling */
  -moz-appearance: none; /* Removes default Firefox styling */
  appearance: none; /* Removes default styling */
  background: #ffffff url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMUw1IDVMOSAxIiBzdHJva2U9IiMzMzMzMzMiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+Cg==') no-repeat right 15px center;
  border: 2px solid #ced4da;
  border-radius: 8px;
  height: 45px;
  color: #7d7e7f; /* Set the text color */
}
/* Adjustments for Firefox */
@-moz-document url-prefix() {
  .form-select {
    text-indent: 0.01px;
    text-overflow: '';
  }
}

/* Initial style for when the default "placeholder" option is selected */
.form-select.default-selected {
  color: #7d7e7f; /* Light grey color */
}

/* Style for when an actual option is selected */
.form-select.option-selected {
  color: black; /* Black color for selected option */
}

/* For IE11 */
.form-select::-ms-expand {
  display: none;
}

/*Editable Row*/
/* EditableRow.css */

.editable-row-container {
  position: relative; /* Establishes a new positioning context */
}

.editable-row {
  text-align: left;
  padding: 20px;
  background-color: white;
  position: relative; /* Needed if you're applying a background-clip or other relative styling */
}

.editable-row2 {
  text-align: center;
  padding: 20px;
  background-color: white;
  position: relative; /* Needed if you're applying a background-clip or other relative styling */
}

.row-divider {
  position: absolute;
  bottom: 0; /* Aligns the divider to the bottom of the parent container */
  left: 20px; /* Adjusts to match the padding of `.editable-row` */
  right: 20px; /* Adjusts to match the padding of `.editable-row` */
  height: 2px;
  background-color: #ced4da; /* Or any color you wish for the divider */
  z-index: 1; /* Ensures the divider is above the previous element */
}


.editable-text {
  font-weight:bold;
  display: block;
  min-height: 20px;
  cursor: pointer;
}

.editable-text2 {
  color: red;
  font-weight:bold;
  display: block;
  min-height: 20px;
  cursor: pointer;
}

.editable-input {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  width: calc(100% - 10px);
}

.editable-list {
  margin-top:15px;
  border: 2px solid #ced4da;
  border-radius: 8px;
  overflow: hidden;
}

/* Add this to your CSS file */
.image-2 {
  transition: transform 0.1s ease; /* This enables a smooth transition effect */
}

.image-2:hover {
  transform: scale(1.1); /* This will scale the image to 110% of its original size on hover */
}

.platform-warning {
  width:100%;
  text-align: center;
  margin-top: 10px; /* Adjust as needed for spacing */
  color: rgb(0, 0, 0); /* Optional: if you want the warning to stand out */
  font-size: 1em; /* Adjust based on your design */
  margin-bottom: 15px;
}
.hidden {
  visibility: hidden;
}

/* CSS file */
.no-bottom-margin {
  margin-top:10px;
  margin-bottom: 0;
}

.checkout-button {
  width: 100%;
  height: 40px;
  color: #fff;
  background-color: #4169e4; /* Original color */
  border: 0;
  border-radius: 8px;
  margin-bottom: 0;
  font-weight: 700;
  display: flex; /* This sets up a flex container, defaulting to row (horizontal) */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  cursor: pointer; /* Indicate it's clickable */
  padding: 0 20px; /* Add some padding on the sides */
  position: relative;
}

.checkout-button:hover {
  background-color: #4B93D3; /* Similar to Tailwind's bg-blue-600 */
}

.checkout-button:active {
  background-color: #3a7cb5; /* A darker shade for the clicked state */
  transform: scale(0.97); /* Slightly scale down to simulate pressing */
}

.load_section {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Horizontally */
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.loading_p {
  height: 30px;
  margin-right: 5px;
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.loading-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-dots span {
  display: block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #333;
  border-radius: 50%;
  opacity: 0.2;
  animation: loadingDots 1.4s infinite ease-in-out both;
}

.loading-dots span:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-dots span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes loadingDots {
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0.2;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}

.resize-image {
  width: 100px; /* Sets the image width to 100 pixels */
  height: auto; /* Adjusts the height automatically to maintain aspect ratio */
}

.sxd {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center; /* Horizontally */
  align-items: flex-end;
}

.checkbox-container {
  display: flex;
  flex-direction: row; /* Align items in a column */
  width: 100%; /* Take up full width */
  justify-content: flex-start; /* Align items to the left */
  padding-top: 15px;
}

.checkbox-label {
  position: relative;
  padding-left: 25px; /* Adjusted space for the smaller custom checkbox */
  cursor: pointer;
  display: inline-block;
  line-height: 20px; /* Adjust based on your design */
}

input[type="checkbox"] {
  width:0px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}

.checkbox-label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 15px; /* Outer circle size */
  height: 15px; /* Outer circle size */
  border: 2px solid #ced4da;
  border-radius: 50%;
  background-color: #fff; /* Default background */
  transition: all 0.2s; /* Smooth transition for both background and border */
}

input[type="checkbox"]:checked + label::before {
  /*border-color: #4169e4; /* Border color when checked */
}

input[type="checkbox"]:checked + label::after {
  content: '';
  position: absolute;
  left: 3px; /* Center the inner circle horizontally */
  top: 3px; /* Center the inner circle vertically */
  width: 13px; /* Make the inner circle bigger */
  height: 13px; /* Make the inner circle bigger */
  background-color: #4169e4; /* Inner circle color when checked */
  border-radius: 50%; /* Ensure it's perfectly circular */
}

.form-input {
  margin-top: 15px;
  width: 100%;
  font-size: 15px;
  padding: 12px 15px; /* Adjust padding to match your design preferences */
  background-color: #ffffff;
  border: 2px solid #ced4da;
  border-radius: 8px;
  height: 45px;
  color: black; /* Font color changed to black */
  box-sizing: border-box; /* Ensure padding doesn't increase the box's size */
}

.form-input::placeholder { /* Targets the placeholder text specifically */
  color: #7d7e7f; /* Changes placeholder text color to black */
}

/* Styles for focus to have a black outline */
.form-input:focus {
  border-color: black; /* Border color is black */
  outline: 0;
  
}

.form-input2 {
  margin-top: 5px;
  width: 100%;
  font-size: 15px;
  padding: 12px 15px 12px 15px; /* Adjust padding to ensure space for the status */
  background-color: #ffffff;
  border: 2px solid #ced4da;
  border-radius: 8px;
  height: 45px;
  color: black;
  box-sizing: border-box;
}

.form-input2:focus {
  border-color: black; /* Enhance focus border */
  outline: none; /* Remove default focus outline */
}

@keyframes spinner {
  to { transform: rotate(360deg); }
}

.spinner {
  border: 2px solid transparent;
  border-top: 2px solid #fff; /* Change color to fit your button */
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spinner .6s linear infinite;
}

@keyframes spinner2 {
  to { transform: rotate(360deg); }
}

.spinner2 {
  border: 2px solid transparent;
  border-top: 2px solid #000; /* Adjust color to fit your design */
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spinner .6s linear infinite;
}

.promo-status {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #d9534f; /* Red color for both valid and invalid to keep uniformity, change if needed */
  font-size: 12px; /* Smaller font size for subtlety */
  font-weight: 400; /* Normal weight to reduce width */
  letter-spacing: 0.2px; /* Slightly increase spacing to make it less crowded */
  text-transform: uppercase; /* Optional: makes it more formal */
}

/* Additional styling for valid status to override red if needed */
.promo-status.valid {
  color: #5cb85c; /* Bootstrap 'success' green */
}