:root {
  --text-gray: #babdc3;
  --white: white;
  --black: black;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

.container {
  box-shadow: none;
  background-color: #fff;
  border: 2px solid #ced4da;
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 590px;
  margin: 20px 0;
  padding-left: 40px;
  padding-right: 40px;
  display: block;
}

.heading {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 400;
}

.paragraph {
  text-align: center;
  flex-direction: row;
  flex: 0 auto;
  justify-content: center;
  align-self: auto;
  align-items: center;
  width: 90%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 17px;
  display: flex;
}

.header-centered-div {
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}

.heading-2 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 27.5px;
  line-height: 32px;
}

.number-of-influencers-div {
  text-align: left;
  width: 100%;
  margin-bottom: 40px;
}

.paragraph-2 {
  color: #6f6e6e;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 17px;
}

.platform-selection-div {
  text-align: left;
  width: 100%;
  margin-bottom: 5px;
}

.section {
  background-image: none;
  background-size: auto;
  background-attachment: scroll;
  justify-content: center;
  height: auto;
  display: flex;
}

.button {
  text-align: center;
  background-color: #000;
  width: 300px;
}

.div-block {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
  display: flex;
}

.heading-3 {
  text-align: left;
  font-size: 20px;
  line-height: 22px;
}

.heading-4 {
  text-align: left;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 22px;
  display: inline-block;
}

.link-block {
  background-color: #000;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 47%;
  height: 45px;
  text-decoration: none;
  display: flex;
}

.heading-5 {
  color: #fff;
  margin-left: 5px;
  margin-right: 10px;
  font-size: 18px;
  text-decoration: none;
}

.image {
  max-width: 25px;
}

.outreach-goal {
  text-align: left;
  width: 100%;
  margin-bottom: 40px;
}

.dropdown {
  justify-content: flex-start;
  width: 100%;
  display: block;
}

.dropdown-toggle {
  border: 2px solid var(--text-gray);
  border-radius: 8px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.icon {
  color: var(--text-gray);
}

.dropdown-link {
  background-color: rgba(107, 105, 105, .17);
  border: 1px solid #8a7b7b;
  border-top-style: none;
  border-radius: 0;
  width: 99%;
  margin-left: 5px;
  padding-left: 15px;
}

.dropdown-list {
  background-color: rgba(221, 221, 221, .03);
}

.dropdown-list.w--open {
  background-color: rgba(221, 221, 221, 0);
}

.target-audiences {
  width: 100%;
  margin-bottom: 40px;
}

.checkout {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.outerccdiv {
  border: 2px solid #ced4da;
  border-radius: 8px;
  width: (100% -4px);
  margin-bottom: 40px;
  margin-left: 0;
  margin-right: 0px;
  overflow: visible;
}

.heading-6 {
  margin-top: 0;
  margin-bottom: 0;
}

.innerccdiv {
  justify-content: space-between;
  margin: 20px 20px 15px;
  display: flex;
}

.paragraph-3 {
  color: #5c5e65;
  text-align: left;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.checkout-button {
  color: #fff;
  text-align: center;
  background-color: #4169e4;
  border: 0 #000;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
  font-weight: 700;
  display: block;
}

.checkout-b-div {
  justify-content: center;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}

.plus-div {
  flex-direction: row;
  align-items: center;
  height: 20px;
  display: none;
}

.ta-div {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.image-2 {
  max-width: 35px;
  margin-left: 5px;
}

.paragraph-4 {
  margin-top: 0;
  margin-bottom: 0;
}

.target-audience-header {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 20px;
}
